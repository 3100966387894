/**
 * Created by Slava on 02.07.2024
 */

DailyTasksTab.prototype.ctor = cleverapps.extendFunc(DailyTasksTab.prototype.ctor, function (window) {
    this._super(window);
    var items = [];

    items.push(new DailyTasksCompleteAllView());
    items.push(this.createScroll());

    this.setItems(items);
});

DailyTasksTab.prototype.createScroll = cleverapps.extendFunc(DailyTasksTab.prototype.createScroll, function () {
    var styles = cleverapps.styles.DailyTasksTab;

    var tasks = this.createTasks();
    var scroll = this.scroll = new cleverapps.UI.ScrollView(tasks);
    scroll.setContentSize2(tasks.width + 2 * styles.scroll.paddingX, styles.scroll.height);
    scroll.setBarPadding(styles.scrollBarPadding);

    var foregroundTop = cleverapps.UI.createScale9Sprite(bundles.dailytasks.frames.foreground_png_2);
    foregroundTop.setContentSize2(styles.foreground.width, styles.foreground.height);
    foregroundTop.setPositionRound(styles.foreground.top);
    scroll.addChild(foregroundTop);

    var foregroundBottom = cleverapps.UI.createScale9Sprite(bundles.dailytasks.frames.foreground_png);
    foregroundBottom.setContentSize2(styles.foreground.width, styles.foreground.height);
    foregroundBottom.setPositionRound(styles.foreground.bottom);
    foregroundBottom.setScaleY(-1);
    scroll.addChild(foregroundBottom);

    return scroll;
});