/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var CrossView = cc.Node.extend({
    ctor: function (cross) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setVisible(cross.village.isStateRaid());
        this.cross = cross;

        var styles = cleverapps.styles.CrossView;

        var icon = this.icon = new cleverapps.Spine(bundles.village.jsons.cross_json);
        this.icon.setAnimation(0, "hover_back", false);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);
        icon.setVisible(!cross.raided);

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });

        var positions = styles.villages[cross.village.level] ? styles.villages[cross.village.level] : styles.positions;
        var stylesPosition = styles.positions[cross.id];
        var position = positions[cross.id].map(function (pos, i) {
            return pos.x !== undefined && pos.y !== undefined ? pos : stylesPosition[i]
        });
        this.setContentSize2(icon.getContentSize());
        this.setPositionRound(position);

        if (cross.raided) {
            this.animateDig();
        } else {
            this.show();
        }

        cleverapps.UI.onClick(this, this.onClick.bind(this), { interactiveScale: false });

        cross.on("dig", this.animateDig.bind(this), this);

        if (cleverapps.config.debugMode) {
            this.setVisible(cross.village.isStateRaid() || !!cleverapps.flags.villageCrossEnabled);
            cleverapps.flags.on("change:villageCrossEnabled", function () {
                this.setVisible(!!cleverapps.flags.villageCrossEnabled);
            }.bind(this));
        }
    },

    show: function () {
        if (!this.cross.village.isStateRaid()) {
            return;
        }

        this.setScale(0.1);
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(1.7 + 0.2 * this.cross.id),
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.main.urls.slot_target);
            }),
            cleverapps.UI.animateScale({
                duration: 0.1,
                scale: 1,
                overScaling: 1.2
            })
        ));
    },

    animateDig: function (cb) {
        var gold = this.cross.gold;
        var styles = cleverapps.styles.CrossView;
        var animation = gold === 0 ? "empty" : "gold";

        var pit = this.pit = new cleverapps.Spine(bundles.village.jsons.dig_animation);
        pit.setPositionRound(styles.pit);
        this.addChild(pit);
        pit.replaceParentSamePlace(cleverapps.scenes.getRunningScene(), {
            keepScale: true,
        });
        pit.setLocalZOrder(4);

        this.icon.runAction(
            new cc.Sequence(
                new cc.PlaySound(bundles.main.urls.slot_raid_dig),
                new cc.SpineAction(this.pit, animation),
                new cc.Hide(),
                new cc.DelayTime(this.pit.getAnimationDuration(animation) - 1),
                new cc.CallFunc(function () {
                    if (animation === "gold") {
                        cleverapps.audio.playSound(bundles.main.urls.fountain_coins);
                    }
                }),
                new cc.DelayTime(1),
                new cc.CallFunc(cb || function () { })
            )
        );
    },

    onClick: function () {
        if (!this.cross.raided) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                this.icon.setAnimation(0, "click_vertical", false);
            } else {
                this.icon.setAnimation(0, "click_horizontal", false);
            }
            this.icon.setCompleteListener(function () {
                this.icon.setCompleteListener();
                this.cross.onClick();
            }.bind(this));
        }
    },

    onMouseOver: function () {
        if (!this.cross.raided) {
            this.icon.setAnimation(0, "hover_forward", false);
        }
    },

    onMouseOut: function () {
        if (!this.cross.raided) {
            this.icon.setAnimation(0, "hover_back", false);
        }
    }
});

cleverapps.styles.CrossView = {
    scale: [1.6, 1, 1],

    pit: {
        x: { align: "center" },
        y: { align: "center", dy: 60 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    villages: [],
    positions: [
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -300 },
            y: { align: "center", dy: -250 }
        }, {
            x: { align: "center", dx: -300 },
            y: { align: "center", dy: -250 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 200 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 320 },
            y: { align: "center", dy: -220 }
        }, {
            x: { align: "center", dx: 320 },
            y: { align: "center", dy: -220 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: -200 }
        }, {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: -300 }
        }, {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: -300 }
        }]
    ]
};