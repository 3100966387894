/**
 * Created by Slava on 04.06.2024
 */

if (cleverapps.config.name === "coinkingdom") {
    Offers.initialize = cleverapps.extendFunc(Offers.initialize, function () {
        this._super();

        Offers[Offers.TYPES.PACK].availableByStages = [
            {
                level: 0
            }, {
                level: 0
            }, {
                level: 0
            }
        ]
    });
}