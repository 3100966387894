/**
 * Created by Mikhail Menshenin on 25.06.2024
 */

cleverapps.override(cleverapps.Availables, {
    LEADER_BOARD: {
        level: 0.1,
        debugMode: true
    },
    DAILY_TASKS: {
        level: 0.1,
        debugMode: true
    },
    PROLONGATION_OFFER: {
        level: 0.2
    },
    YATZY_COMBO: {
        level: 0.2
    },
    FREE_PROLONGATION_FORCE: {
        level: 0.2
    },
    STICKERS_BOOK: {
        level: 0
    }
});

cleverapps.MiniGame.AVAILABLE = {
    level: 0.1,
    debugMode: true,
    registered: "1 hour",
    epicart: {
        disabled: true
    }
};
