/**
 * Created by Mikhail Menshenin on 16.07.2024
 */

var VillageManager = function () {
    this.villages = {};
    this.configs = this.getConfigs();

    cleverapps.meta.on("moveNextLocation", this.moveNextLocation.bind(this));
};

VillageManager.prototype.getVillage = function (player) {
    player = player || this.getCurrentPlayer();
    var playerId = player.id;
    var isUser = this.isUser(player);
    var village = player.village || this.villages[playerId];

    if (!village) {
        var villageLevel = player.villageLevel || 0;
        player.villageLevel = villageLevel;
        var config = this.configs[player.villageLevel] || {};
        var data = isUser ? this.load(villageLevel) : this.generate(config);
        village = new Village(config, player);
        village.load(aisensia.savedVillage || data);

        this.villages[playerId] = village;
    }

    player.village = village;

    return village;
};

VillageManager.prototype.getVillageLevel = function () {
    var village = this.getVillage();
    return village && village.level || 0;
};

VillageManager.prototype.getAttackVillage = function () {
    var player = aisensia.villagePlayers.getAttackPlayer();
    return this.getVillage(player);
};

VillageManager.prototype.getRaidVillage = function () {
    var player = aisensia.villagePlayers.getRaidPlayer();
    return this.getVillage(player);
};

VillageManager.prototype.load = function () {
    return cleverapps.meta.location.data;
};

VillageManager.prototype.save = function (village) {
    if (this.isUser(village.player)) {
        cleverapps.meta.getMainObject().saveData(village.getInfo());
    }
};

VillageManager.prototype.isUser = function (player) {
    var userId = connector.platform.getUserID();
    return userId === player.id;
};

VillageManager.prototype.generate = function (config) {
    var data = {};

    data.gold = cleverapps.Random.random(50, 99) * 1000;

    data.huts = config.huts.reduce(function (huts, hut) {
        var level = cleverapps.Random.random(0, Hut.MAX_LEVEL);

        if (level) {
            var data = { level: level };
            var damage = cleverapps.Random.random(0, level - 1);
            if (damage) {
                data.damage = damage;
            }
            huts[hut.name] = data;
        }

        return huts;
    }, {});

    return data;
}

VillageManager.prototype.getCurrentPlayer = function () {
    var villageLevel = cleverapps.meta.currentLocationId;
    return {
        id: connector.platform.getUserID(),
        villageLevel: villageLevel
    };
}

VillageManager.prototype.canMoveNext = function () {
    var village = this.getVillage();

    return village.level < this.getMaxLevel();
};

VillageManager.prototype.moveNext = function () {
    if (!this.canMoveNext()) {
        cleverapps.focusManager.display({
            focus: "NextVillageScene",
            control: [],
            action: function (f) {
                new AllDoneWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
        return;
    }

    cleverapps.focusManager.display({
        focus: "NextVillageScene",
        control: [],
        actions: [
            function (f) {
                this.clearVillageCache();
                f();
            }.bind(this),
            function (f) {
                cleverapps.meta.moveNextLocation(f);
            }
        ]
    });
};

VillageManager.prototype.completeLocation = function (f) {
    f();
};

VillageManager.prototype.moveNextLocation = function (f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            cleverapps.scenes.replaceScene(new VillageScene(), f);
        },
        function (f) {
            var village = this.getVillage();
            this.save(village);
            f();
        }.bind(this)
    ]);
};

VillageManager.prototype.clearVillageCache = function (player) {
    player = player || this.getCurrentPlayer();

    if (this.villages[player.id]) {
        delete this.villages[player.id];
    }

    if (player.village) {
        delete player.village;
    }
}

VillageManager.prototype.getMaxLevel = function () {
    return Math.max.apply(Math, Object.keys(this.configs));
};

VillageManager.prototype.getBackgroundBundle = function (index) {
    return (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "village_background_vertical_" : "village_background_horizontal_") + index;
}

VillageManager.prototype.getConfigs = function () {
    var configs = [];
    for (var id = 0; ; id++) {
        var bundleBgIds = [
            "village_background_horizontal_" + id,
            "village_background_vertical_" + id
        ];
        var existsBg = bundleBgIds.filter(function (bundleId) {
            return !bundles[bundleId];
        }).length === 0;
        if (!existsBg) {
            break;
        }
        var config = {
            level: id,
            name: "village_" + id
        };

        var huts = {};
        var bundleId = "village_" + id;
        var bundle = bundles[bundleId];
        if (bundle) {
            var frames = Object.keys(bundle.frames);
            frames.forEach(function (frame) {
                var hut = frame.slice(0, frame.lastIndexOf("_"));
                var level = parseInt(frame.slice(hut.length + 1));
                if (!huts[hut]) {
                    huts[hut] = {
                        name: hut,
                        maxLevel: level
                    }
                }
                if (huts[hut].maxLevel < level) {
                    huts[hut].maxLevel = level;
                }
            });
        }
        config.huts = Object.keys(huts).sort().map(function (hut) {
            return huts[hut];
        });

        configs.push(config);
    }
    return configs;
};

if (cleverapps.config.debugMode) {
    VillageManager.prototype.reset = function () {
        cleverapps.meta.reset();
        cleverapps.meta.currentLocationId = -1;
        var village = this.getVillage();
        village.level = 0;
        this.moveNext();
    };
}

cleverapps.InitByFeature["villages"] = function () {
    aisensia.villageManager = new VillageManager();
};

cleverapps.whenAllInitialized(function () {
    aisensia.villagePlayers = new VillagePlayers();
});
