/**
 * Created by Mikhail Menshenin on 10.07.2024
 */

var VillageUpgradeWindow = CleverappsWindow.extend({
    onWindowLoaded: function (village) {
        var styles = cleverapps.styles.VillageUpgradeWindow;

        this._super({
            title: "VillageUpgradeWindow.Title",
            name: "VillageUpgradeWindow",
            content: this.createContent(village, styles),
            styles: styles
        });
    },

    createContent: function (village, styles) {
        var rows = village.huts.map(function (hut) {
            return this.createRow(hut, styles.row);
        }.bind(this));

        var layout = new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.table.margin,
            padding: styles.table.padding
        });

        return layout;
    },

    createRow: function (hut, styles) {
        var bg = cleverapps.UI.createScale9Sprite(hut.isComplete() ? bundles.windows.frames.windows_group_bg_completed_png : bundles.windows.frames.windows_group_bg_png);
        bg.setContentSize(styles);

        var icon = this.createIconWithProgress(hut, styles);
        if (cleverapps.config.debugMode) {
            cleverapps.UI.onClick(icon, function () {
                if (!hut.isComplete()) {
                    hut.setLevel(hut.level + 1);
                }
            }, {
                interactiveScale: false
            });
        }

        var price = hut.getPrice();
        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            width: styles.button.width,
            height: styles.button.height,
            text: TextWithIcon.ICONS_BY_NAME[price.type] + price.amount,
            onClicked: hut.upgrade.bind(hut)
        });
        if (!hut.upgradeable) {
            button.disable();
        }

        var completed = cleverapps.UI.generateOnlyText("complete!", cleverapps.styles.FONTS.VILLAGE_UPGRADE_COMPLETE);

        var layout = new cleverapps.Layout([icon, hut.isComplete() ? completed : button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        layout.setPositionRound(styles.position);
        bg.addChild(layout);

        hut.once("changeLevel", this.createListener(this.close.bind(this)));

        return bg;
    },

    createIconWithProgress: function (hut, styles) {
        var frame = hut.getFrame();

        var iconNode = new cc.Node();
        iconNode.setAnchorPoint(0.5, 0.5);
        iconNode.setContentSize(styles.icon);

        var icon = new cc.Sprite(frame);
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(styles.icon.position);
        iconNode.addChild(icon);

        var nextIconNode = new cc.Node();
        nextIconNode.setAnchorPoint(0.5, 0.5);
        nextIconNode.setContentSize(styles.next_icon);

        var nextFrame = hut.getFrame(hut.level + 1);
        var nextIcon = new cc.Sprite(nextFrame);
        cleverapps.UI.fitToBox(nextIcon, styles.next_icon);

        nextIcon.setPositionRound(styles.next_icon.position);
        nextIconNode.addChild(nextIcon);

        var iconBlockItems = hut.isComplete() ? [iconNode] : [nextIconNode];

        if (hut.level && !hut.isComplete()) {
            iconNode.setContentSize(styles.small_icon);
            var arrow = new cc.Sprite(bundles.villageupgrade_window.frames.arrow_png);
            iconBlockItems.unshift(arrow);
            cleverapps.UI.fitToBox(icon, styles.small_icon);
            iconBlockItems.unshift(iconNode);
            icon.setPositionRound(styles.icon.position);
        }

        var iconBlock = new cleverapps.Layout(iconBlockItems, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.icon_block.margin,
            padding: styles.icon_block.padding
        });

        var progress = this.createProgressBar(hut, styles);

        iconBlock.setContentSize(progress.getContentSize().width, styles.icon_block.height);

        var layout = new cleverapps.Layout([iconBlock, progress], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.icon_with_progress.margin,
            padding: styles.icon_with_progress.padding
        });

        return layout;
    },

    createProgressBar: function (hut, styles) {
        var bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_green_small,
            background: bundles.progress_bar.frames.bg_dark_small
        });
        bar.setLength(styles.bar.width);
        bar.setGoal(hut.upgrades.length - 1);
        bar.setPercentage(hut.level ? hut.level - 1 : 0);

        var starFrame = bundles.villageupgrade_window.frames.star_png;
        var starShadowFrame = bundles.villageupgrade_window.frames.star_shadow_png;
        for (var i = 0; i < 4; i++) {
            var star = new cc.Sprite(i < hut.level ? starFrame : starShadowFrame);
            star.setAnchorPoint(0.5, 0.5);
            star.setPosition(bar.width * i / 3, bar.height / 2 + styles.bar.starPadding);
            bar.addChild(star);
        }

        return bar;
    },

    listBundles: function () {
        return ["villageupgrade_window"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_UPGRADE_COMPLETE: {
        size: 45,
        color: cleverapps.styles.COLORS.ORANGE_TEXT_COLOR,
        stroke: {
            color: cleverapps.styles.COLORS.DARK_BROWN_STROKE,
            size: 3
        },
    }
});

cleverapps.styles.VillageUpgradeWindow = {
    table: {
        margin: 12,
        padding: {
            bottom: 0,
            top: 35,
            x: -10
        }
    },

    row: {
        width: 850,
        height: 230,
        margin: 80,
        padding: 35,
        position: {
            x: { align: "center", dx: 10 },
            y: { align: "center" }
        },

        icon: {
            width: 180,
            height: 180,
            position: {
                x: { align: "center" },
                y: { align: "bottom" }
            }
        },

        small_icon: {
            width: 160,
            height: 120
        },

        next_icon: {
            width: 210,
            height: 150,
            position: {
                x: { align: "center" },
                y: { align: "bottom" }
            }
        },

        icon_block: {
            margin: 30,
            padding: 0,
            height: 120
        },

        icon_with_progress: {
            margin: 10,
            padding: {
                bottom: 0,
                top: 20,
                x: 0
            },
            position: {
                x: { align: "center" },
                y: { align: "center", dy: -10 }
            }
        },

        bar: {
            width: 440,
            starPadding: 5
        },

        button: {
            width: 220,
            height: 80
        }
    }
};