/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var HutView = cc.Node.extend({
    avoidNode: "HutView",

    ctor: function (hut) {
        this._super();
        this.hut = hut;

        this.setAnchorPoint(0.5, 0.5);

        this.createImage();

        if (this.hut.canBuild()) {
            this.createIcon();
        }

        if (this.hut.canAttack()) {
            this.createScope();
        }

        cleverapps.UI.wrap(this);

        this.checkUpgradeable();
        this.setupChildren();

        hut.on("changeLevel", this.animateChangeLevel.bind(this), this);
        hut.on("attack", this.attackAnimation.bind(this), this);
        hut.on("changeUpgradeable", this.checkUpgradeable.bind(this), this);
    },

    createIcon: function () {
        var styles = cleverapps.styles.HutView;
        var icon = new cc.Sprite(bundles.village.frames.build_png);
        icon.setPositionRound(styles.icon);
        icon.setScale2(styles.icon.scale);
        this.addChild(icon, 10);
        cleverapps.UI.onClick(icon, this.hut.onClick.bind(this.hut), { interactiveScale: false });
        this.icon = new HidingNode(icon, HidingNode.DIR.NONE);
        cleverapps.focusManager.registerControl("buildIcon" + this.hut.id, this.icon);
    },


    createScope: function () {
        var styles = cleverapps.styles.HutView;
        var targetIcon = new HutScopeView(this.hut);
        targetIcon.setPositionRound(styles.scope);
        this.addChild(targetIcon);
        var control = new HidingNode(targetIcon);
        cleverapps.focusManager.registerControl("villageScope" + this.hut.id, control);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.HutView;
        var positions = styles.villages[this.hut.village.level] || styles.positions;
        var stylesPosition = styles.positions[this.hut.id];
        var position = positions[this.hut.id].map(function (pos, i) {
            return pos.x !== undefined && pos.y !== undefined ? pos : stylesPosition[i]
        });
        this.setPositionRound(position);
    },

    createImage: function () {
        var styles = cleverapps.styles.HutView.image;
        var frame = this.hut.getFrame();
        if (frame) {
            this.image = new cc.Sprite(frame);
            this.image.setPositionRound(styles);
            this.image.setScale(0.6);
            this.addChild(this.image);
            cleverapps.UI.onClick(this.image, this.hut.onClick.bind(this.hut), { interactiveScale: false });
            cleverapps.UI.wrap(this);
        } else {
            this.image = undefined;
        }
    },

    animateChangeLevel: function (callback, withoutCloud) {
        var oldImage = this.image;
        this.createImage();

        if (!oldImage) {
            oldImage = this.image;
            oldImage.setVisible(false);
        }

        this.icon && this.icon.toggle(false);
        AnimationsLibrary.upgrade(oldImage, this.image, {
            cloud: !withoutCloud,
            parent: cleverapps.scenes.getMovingNode(this),
            callback: function () {
                this.icon && this.icon.toggle(!this.hut.isComplete());
                callback && callback();
            }.bind(this)
        });
    },

    attackAnimation: function (f) {
        this.animateChangeLevel(f, true);

        var styles = cleverapps.styles.HutView;
        var spine = new cleverapps.Spine(bundles.village.jsons.small_lightning_json);
        spine.setPositionRound(styles.lightning);
        this.addChild(spine);
        spine.setAnimation(0, "animation", false);
        spine.setCompleteListenerRemove();

        var smoke = new cleverapps.Spine(bundles.village.jsons.smoke_json);
        smoke.setPositionRound(styles.smoke);
        smoke.setLocalZOrder(-1);
        this.addChild(smoke);
        smoke.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.2),
            new cc.Show(),
            new cc.CallFunc(function () {
                smoke.setAnimation(0, "smoke", true);
            })
        ));
    },

    checkUpgradeable: function () {
        if (this.icon) {
            if (this.hut.upgradeable) {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_png);
            } else {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_grey_png);
            }
            this.icon.toggle(!this.hut.isComplete(), true);
        }
    },

    spawn: function () {
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(this.hut.id * 0.2),
            AnimationsLibrary.showUp(this)
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HUT_LEVEL_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },
    HUT_DAMAGE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.HutView = {
    width: [300, 390, 420],
    height: [300, 252, 252],

    icon: {
        x: { align: "center" },
        y: { align: "center" },
        scale: [0.73, 0.57, 0.57],
    },

    villages: [],
    positions: [
        [{
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: 450 }
        }, {
            x: { align: "center", dx: -290 },
            y: { align: "center", dy: 280 }
        }, {
            x: { align: "center", dx: -310 },
            y: { align: "center", dy: 280 }
        }],
        [{
            x: { align: "center", dx: 240 },
            y: { align: "center", dy: 190 }
        }, {
            x: { align: "center", dx: 290 },
            y: { align: "center", dy: 280 }
        }, {
            x: { align: "center", dx: 310 },
            y: { align: "center", dy: 280 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: -50 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: 210 },
            y: { align: "center", dy: -340 }
        }, {
            x: { align: "center", dx: -420 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -450 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: -220 },
            y: { align: "center", dy: -380 }
        }, {
            x: { align: "center", dx: 420 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 450 },
            y: { align: "center", dy: 0 }
        }]
    ],

    attention: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 },

        scale: 0.5
    },

    image: {
        x: { align: "center" },
        y: { align: "center" }
    },

    scope: {
        x: { align: "center" },
        y: { align: "center" }
    },

    lightning: {
        x: { align: "center" },
        y: { align: "center" }
    },

    smoke: {
        x: { align: "center" },
        y: { align: "center" }
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("HutView");