/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        this.village = village;

        this.hutViews = village.huts.map(function (hut) {
            var hutView = new HutView(hut);
            this.addChild(hutView);
            return hutView;
        }.bind(this));

        village.crosses.forEach(function (cross) {
            var crossView = new CrossView(cross);
            this.addChild(crossView);
        }.bind(this));

        this.movingNode = new cc.Node();
        this.addChild(this.movingNode);

        village.on("assault", this.showAssaultAnimation.bind(this), this);
        village.on("defense", this.showDefenseAnimation.bind(this), this);
    },

    showAnimation: function (silent) {
        this.setVisible(true);
        if (silent) {
            return;
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.main.urls.objects);
            }),
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                if (this.village.isStateAttack()) {
                    cleverapps.audio.playSound(bundles.main.urls.aim);
                }
            }.bind(this))
        ));
        this.hutViews.forEach(function (hutView) {
            if (!this.village.isStateBuild()) {
                hutView.spawn();
            }
        }.bind(this));
    },

    showAssaultAnimation: function (f) {
        cleverapps.audio.playSound(bundles.main.urls.lightning);

        this.runAction(
            new cc.Sequence(
                new cc.DelayTime(0.3),
                new cc.CallFunc(f)
            )
        );
    },

    showDefenseAnimation: function (f) {
        cleverapps.audio.playSound(bundles.main.urls.lightning_shield);

        var spine = new cleverapps.Spine(bundles.village.jsons.village_attack_json);
        spine.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        this.addChild(spine);

        spine.runAction(
            new cc.Sequence(
                spine.createAction("defend"),
                new cc.delayTime(spine.getAnimationDuration("defend")),
                new cc.CallFunc(function () {
                    spine.removeFromParent(true);
                    f();
                })
            )
        );
    }
});
