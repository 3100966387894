/**
 * Created by Mikhail Menshenin on 29.08.2024
 */

var RaidControlPanelView = cc.Node.extend({
    ctor: function (village, noAvatar) {
        this._super();
        this.village = village;

        var styles = cleverapps.styles.RaidControlPanelView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var sprite = bundles.village.frames.control_panel_bg.spriteFrame ? bundles.village.frames.control_panel_bg : bundles.transition_raid.frames.control_panel_bg;

        var nameBg = this.nameBg = cleverapps.UI.createScale9Sprite(sprite);
        nameBg.setContentSize(styles.namePanel);
        nameBg.setPositionRound(styles.namePanel);
        this.addChild(nameBg);

        if (bundles.village.frames.control_panel_bg_highlight.spriteFrame) {
            var highlightBg = this.highlightBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg_highlight);
            highlightBg.setContentSize(styles.namePanel);
            highlightBg.setPositionRound(styles.namePanel);
            this.addChild(highlightBg);
            highlightBg.setOpacity(0);
        }

        var controlPanelBg = cleverapps.UI.createScale9Sprite(sprite);
        controlPanelBg.setContentSize(styles);
        controlPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(controlPanelBg);

        var player = village.player;
        var avatar = this.avatar = new cleverapps.UI.Avatar(player);
        avatar.setPositionRound(styles.avatar);
        avatar.setScale(1.3);
        this.addChild(avatar);
        avatar.setVisible(!noAvatar);

        var nameText = this.nameText = cleverapps.UI.generateOnlyText("%{name}'s treasure:", cleverapps.styles.FONTS.RAID_NAME_TEXT, { name: cleverapps.UI.cutPlayerName(player.name, 7) });
        nameText.setPositionRound(styles.name);
        this.addChild(nameText);

        var goldText = this.goldText = new TextWithIcon(aisensia.getNumberWithCommas(village.gold) + " " + TextWithIcon.ICONS_BY_NAME.soft, styles.gold.options);
        goldText.setPositionRound(styles.gold);
        this.addChild(goldText);

        var labelText = this.labelText = cleverapps.UI.generateOnlyText("You stole:", cleverapps.styles.FONTS.RAID_NAME_TEXT);
        labelText.setPositionRound(styles.label);
        nameBg.addChild(labelText);

        var earnedText = this.earnedText = new TextWithIcon(aisensia.getNumberWithCommas(village.goldCollected) + " " + TextWithIcon.ICONS_BY_NAME.soft, styles.earned.options);
        earnedText.setAnchorPoint(0, 0);
        earnedText.setPositionRound(styles.earned);
        nameBg.addChild(earnedText);
        earnedText.setCascadeOpacityEnabledRecursively(true);
        this.goldCollectedAlready = village.goldCollected || 0;

        cleverapps.aims.registerTarget("earnedText", earnedText, {
            controls: "earnedText",
            noTargetDelta: true
        });

        village.on("changeGoldCollected", this.countGoldCollected.bind(this), this);
        village.on("animateGoldHighlight", this.animateGoldHighlight.bind(this), this);

        this.setContentSize(controlPanelBg.getContentSize());
    },

    showSlotMachine: function (cb) {
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                this.nameText.setOpacity(0);
                this.goldText.setOpacity(0);
                this.goldText.setVisible(false);
                this.labelText.setVisible(false);
                this.earnedText.setVisible(false);
                this.nameBg.setVisible(false);
            }.bind(this)),
            new cc.MoveBy(0, 0, this.height),
            new cc.DelayTime(0.5),
            new cc.Show(),
            new cc.MoveBy(0.3, 0, -this.height).easing(cc.easeOut(3)),
            new cc.CallFunc(this.showRaidedBlock.bind(this)),
            new cc.DelayTime(cb ? 1.2 : 0),
            new cc.CallFunc(function () {
                if (cb) {
                    cb();
                }
            })
        ));
    },

    showRaidScene: function (cb) {
        this.labelText.setOpacity(0);
        this.earnedText.setOpacity(0);
        this.nameBg.runAction(new cc.Sequence(
            new cc.MoveBy(0, 0, this.nameBg.height),
            new cc.DelayTime(2.8),
            new cc.MoveBy(0.2, 0, -this.nameBg.height).easing(cc.easeOut(3)),
            new cc.CallFunc(this.showStealBlock.bind(this)),
            new cc.DelayTime(cb ? 2 : 0),
            new cc.CallFunc(function () {
                if (cb) {
                    cb();
                }
            })
        ));
    },

    hide: function () {
        this.runAction(new cc.Sequence(
            new cc.MoveBy(0.3, 0, this.height).easing(cc.easeIn(3)),
            new cc.Hide()
        ));
    },

    showRaidedBlock: function () {
        this.avatar.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                this.avatar.setVisible(true);
            }.bind(this))
        ));

        this.nameText.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.FadeIn(0.2)
        ));

        this.goldText.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.CallFunc(function () {
                this.goldText.setVisible(true);
            }.bind(this)),
            new cc.FadeIn(0.2)
        ));
    },

    showStealBlock: function () {
        this.labelText.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.FadeIn(0.1)
        ));

        this.earnedText.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.FadeIn(0.1)
        ));
    },

    countGoldCollected: function (cb) {
        this.earnedText.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.main.urls.count_coins),
            AnimationsLibrary.countTo(this.earnedText, this.village.goldCollected / 1000, {
                duration: 0.5,
                from: this.goldCollectedAlready / 1000,
                getString: function (value) {
                    return aisensia.getNumberWithCommas(value * 1000) + " " + TextWithIcon.ICONS_BY_NAME.soft;
                }
            }),
            new cc.CallFunc(function () {
                this.goldCollectedAlready = this.village.goldCollected;
                cb && cb();
            }.bind(this))
        ));
    },

    animateGoldHighlight: function () {
        this.highlightBg.runAction(new cc.Sequence(
            new cc.FadeTo(0.2, 220),
            new cc.FadeOut(0.2)
        ));
    },

    animateRaidPlayer: function (cb) {
        this.labelText.setVisible(false);
        this.earnedText.setVisible(false);

        var position = this.getPosition();
        this.setPosition(position.x, position.y + this.height);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.MoveBy(0.1, 0, -this.height).easing(cc.easeOut(1)),
            new cc.DelayTime(0.8),
            new cc.CallFunc(cb || function () { })
        ));

        this.nameText.setOpacity(0);
        this.nameText.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.FadeIn(0.1)
        ));

        this.goldText.setCascadeOpacityEnabledRecursively(true);
        this.goldText.setOpacity(0);
        this.goldText.setScale(0);
        this.goldText.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.Spawn(
                new cc.FadeIn(0.1),
                new cc.scaleTo(0.2, 1).easing(cc.easeBackOut())
            )
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RAID_GOLD_TEXT: {
        name: "nostroke",
        size: 36,
        color: new cc.Color(73, 167, 65),
        stroke: undefined,
        shadow: undefined
    },
    RAID_NAME_TEXT: {
        name: "nostroke",
        size: 36,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});


cleverapps.styles.RaidControlPanelView = {
    width: 688,
    height: 260,

    avatar: {
        x: { align: "left", dx: 28 },
        y: { align: "center", dy: -12 },

        padding: {
            x: 10,
            top: 8,
            bottom: 12
        }
    },

    name: {
        x: { align: "left", dx: 204 },
        y: { align: "center", dy: 16 },
    },

    gold: {
        x: { align: "left", dx: 204 },
        y: { align: "center", dy: -60 },

        options: {
            font: cleverapps.styles.FONTS.RAID_GOLD_TEXT
        }
    },

    label: {
        x: { align: "center", dx: -95 },
        y: { align: "center", dy: -27 },
    },

    earned: {
        x: { align: "center", dx: 65 },
        y: { align: "center", dy: -27 },

        options: {
            font: cleverapps.styles.FONTS.RAID_GOLD_TEXT
        }
    },

    namePanel: {
        x: { align: "center" },
        y: { align: "bottom", dy: -80 },

        width: 535,
        height: 150
    },
};