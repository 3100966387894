/**
 * Created by Slava on 07.06.2024
 */

BaseProductTile.prototype.wrapTitleWithBg = cleverapps.extendFunc(BaseProductTile.prototype.wrapTitleWithBg, function (title) {
    if (!title) {
        return;
    }

    var styles = this.styles.title;

    var background = new cleverapps.Spine(bundles.tile_shop.jsons.pack_shop_title_json);
    background.setAnimation(0, "animation", false);
    background.setScale(this.width / background.width, styles.height / background.height);
    background.setAnchorPoint(0, 0);

    var node = new cc.Node();
    node.setContentSize(this.width, background.height);
    node.setAnchorPoint(0.5, 0.5);

    if (title.fitTo) {
        title.fitTo(this.width * 0.9);
    }
    title.setPositionRound(styles.text);

    node.addChild(background);
    node.addChild(title);
    return node;
});