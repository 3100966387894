/**
 * Created by Slava on 25.11.2024
 */

MissionWindow.prototype.createTimer = cleverapps.extendFunc(MissionWindow.prototype.createTimer, function () {
    var styles = cleverapps.styles.MissionWindow;
    var timeLeft = this.mission.getTimeLeft();
    if (timeLeft > 0) {
        timeLeft = new cleverapps.CountDown(timeLeft, {
            onFinish: function () {
                if (this.onFinishedSound && typeof this.onFinishedSound === "function") {
                    this.onFinishedSound(true);
                }
                this.close();
            }.bind(this)
        });
    }

    var timer = new cleverapps.CountDownView(timeLeft, {
        font: cleverapps.styles.FONTS.MISSION_WINDOW_COUNTDOWN_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT,
        background: {
            frame: styles.timerBackground.frame,
            width: styles.timerBackground.width,
            height: styles.timerBackground.height
        }
    });

    return timer;
});