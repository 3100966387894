/**
 * Created by Mikhail Menshenin on 17.10.2024
 */

var VillageAttackResultView = cc.Node.extend({
    ctor: function (village) {
        this._super();

        var styles = cleverapps.styles.VillageAttackResultView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var controlPanelBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg);
        controlPanelBg.setContentSize(styles);
        controlPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(controlPanelBg);

        var player = village.player;
        var playerLabel = cleverapps.UI.generateOnlyText(village.defended() ? "VillageAttackScene.defendedPlayerLabel" : "VillageAttackScene.attackedPlayerLabel", cleverapps.styles.FONTS.DARK_TEXT);
        var playerName = cleverapps.UI.generateOnlyText(cleverapps.UI.cutPlayerName(player.name, 8), cleverapps.styles.FONTS.VILLAGE_ATTACK_PLAYER_NAME_TEXT);
        var playerNameLayout = new cleverapps.Layout([playerLabel, playerName], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.player.margin
        });
        playerNameLayout.setPositionRound(styles.player);
        this.addChild(playerNameLayout);

        var rewardLabel = cleverapps.UI.generateOnlyText("VillageAttackScene.rewardLabel", cleverapps.styles.FONTS.DARK_TEXT);
        var goldText = this.goldText = new TextWithIcon(aisensia.getNumberWithCommas(village.goldCollected) + " " + TextWithIcon.ICONS_BY_NAME.soft, {
            font: cleverapps.styles.FONTS.VILLAGE_ATTACK_RESULT_TEXT
        });

        var rewardLayout = new cleverapps.Layout([rewardLabel, goldText], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.reward.margin
        });
        rewardLayout.setPositionRound(styles.reward);
        this.addChild(rewardLayout);
    },

    show: function () {
        var position = this.getPosition();
        this.setPosition(position.x, position.y - this.height);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveBy(0.4, 0, this.height).easing(cc.easeOut(1))
        ));
        cleverapps.audio.playSound(bundles.main.urls.control_panel_bg);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    VILLAGE_ATTACK_PLAYER_NAME_TEXT: {
        size: 45,
        color: new cc.Color(244, 64, 166)
    },

    VILLAGE_ATTACK_RESULT_TEXT: {
        name: "default",
        size: 40,
        color: new cc.Color(0, 208, 5),
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.VillageAttackResultView = {
    width: 766,
    height: 220,

    player: {
        margin: 8,

        x: { align: "center" },
        y: { align: "bottom", dy: 124 }
    },

    reward: {
        margin: 8,

        x: { align: "center" },
        y: { align: "bottom", dy: 54 }
    }
};