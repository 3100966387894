/**
 * Created by Mikhail Menshenin on 17.10.2024
 */

var HutScopeView = cc.Node.extend({
    ctor: function (hut) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.hut = hut;

        var icon = this.icon = new cleverapps.Spine(bundles.village.jsons.target_json);
        this.setContentSize2(icon.getContentSize());
        icon.setPosition(this.width / 2, this.height / 2);
        this.addChild(icon);

        cleverapps.UI.applyHover(this, {
            onMouseOver: this.onMouseOver.bind(this),
            onMouseOut: this.onMouseOut.bind(this)
        });
        cleverapps.UI.onClick(this, this.onClick.bind(this), { interactiveScale: false });
    },

    showAnimation: function (silent) {
        if (this.hut.village.canAttack() && this.hut.canAttack()) {
            this.setVisible(true);
        } else {
            this.setVisible(false);
            return;
        }

        if (silent) {
            this.icon.setScale(1);
            return;
        }

        this.icon.setScale(0);
        this.icon.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.2 * this.hut.id),
            AnimationsLibrary.showUp(this)
        ));
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.icon.setScale(0);
            return;
        }

        this.icon.setScale(1);

        this.icon.runAction(new cc.Sequence(
            cleverapps.UI.animateScale({
                duration: 0.2,
                scale: 0
            }),
            new cc.Hide()
        ));
    },

    onClick: function () {
        this.icon.setAnimation(0, "click", false);
        this.icon.setCompleteListener(function () {
            this.icon.setCompleteListener();
            this.hut.onClick();
        }.bind(this));
    },

    onMouseOver: function () {
        this.icon.setAnimation(0, "hover_forward", false);
    },

    onMouseOut: function () {
        this.icon.setAnimation(0, "hover_back", false);
    }
});
