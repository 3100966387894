/**
 * Created by Mikhail Menshenin on 28.08.2024
 */

var VillageActionScene = VillageScene.extend({
    onSceneLoaded: function (sceneName) {
        this._super(sceneName);

        this.prepareFinishEventHandler();
    },

    scaleUpAnimation: function () {
        this.background.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.4, 1.2 * this.background.scale).easing(cc.easeOut(1))
            )
        );
    },

    prepareFinishEventHandler: function () {
        this.handleFinishEvent(function () {
            cleverapps.focusManager.display({
                focus: cleverapps.environment.scene + "Finish",
                action: function (f) {
                    this.village.receiveGold();
                    cleverapps.meta.wantsToPlay(f);
                }.bind(this)
            });
        }.bind(this));
    },

    handleFinishEvent: function (f) {
        throw new Error("Implement in successor");
    }
});

MenuControlButton.isAvailableInEpisode = cleverapps.extendFunc(MenuControlButton.isAvailableInEpisode, function () {
    if (cleverapps.environment.hasScene([cleverapps.Environment.SCENE_EDITOR, cleverapps.Environment.SCENE_VILLAGE_ATTACK, cleverapps.Environment.SCENE_VILLAGE_RAID])) {
        return false;
    }

    return this._super();
});